
import { defineComponent, computed, ref, onMounted, onBeforeUnmount, onBeforeMount,onUpdated } from "vue";
import FhForm from "@/components/networks/fh/form/FhForm.vue";
import { useField } from "vee-validate";

export default defineComponent({
  name: "step-1",
  props: { bandeInfo: Object },

  setup(props){

    // Affichage du nom du tableau
    const fhFormRef = ref<typeof FhForm>();

    const showStepLabel = computed(() => 
    {
      let subtitle = "Veuillez préciser les détails du réseau"
      let title = "Réseau FH",
          icon = "las la-wifi fs-1";
      return {title, subtitle, icon}
    })

    onMounted(() => {
      
      if(fhFormRef.value)
      {        
        fhFormRef.value.bande = props.bandeInfo?.fh.bande
      }
      
    });
        
    return{
      showStepLabel,
      fhFormRef,
    }
  },
  components: {
    FhForm
  },
});
