
    export interface BandeX {
        id: string;
        libelle: string;
        debut: number;
        fin: number;
      }
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ObservationRecap from "@/components/customs/ObservationRecap.vue"
import FhListeRecap from "@/components/networks/fh/form/liste/FhListeRecap.vue"
import { useBande } from "@/composables/useBande";

export default defineComponent({
  name: "fh-recap",
  props: {
     data : {type:Object, default:null}
  },
    setup(props){
    const store   = useStore();
    const { getBandeDescription,bandeIsAutre} =useBande("fh");


    const hasObservation =computed(() => {
      return props.data.fh.observation != "" && props.data.fh.observation != undefined && props.data.fh.observation != null
    })

    const bandeDescription =computed(() => 
    {
      return getBandeDescription(props.data.fh.bande.id)
    })

    
    return { 
      hasObservation,
      bandeDescription,
      bandeIsAutre
      
    }
  },
  components: {ObservationRecap,FhListeRecap},
});
