import { computed, defineComponent, onMounted, ref, provide, onBeforeMount, readonly, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useField, useForm } from "vee-validate";
import { useStore } from "vuex";
import {  useRouter, useRoute } from "vue-router";
import {AlertMessage} from "@/bundle/AlertMessage"
import ApiService from "@/core/services/ApiService"
import Yup from "@/bundle/validations/YupExpended";
import Step1 from "./steps/Step1.vue";
import Step2 from "./steps/Step2.vue";
import Step3 from "./steps/Step3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { StepFH } from "@/core/models/guest/StepFH";
import * as JWT from "@/core/services/JwtService";
import { TypeDocument } from "@/core/models/TypeDocument";
import { RegexList } from "@/bundle/RegexList";
import { useBande } from "@/composables/useBande";
import { ACCEPTED_FILETYPE, ACCEPTED_FILETYPE_MESSAGE } from "@/core/models/enum/enumeration";
import {Actions, Mutations} from '@/store/enums/BackStoreEnum';
import {Actions as ActionsF, Mutations as MutationsF} from '@/store/enums/StoreEnums';
import BandeModule from "@/store/modules/BandeModule";

interface Document {
  type: string;
  typeDocument:TypeDocument|null;
  size: number;
  name: string;
  uri: String|any
}
interface IStep1 {
  fh : StepFH;
  declarationId: string,
  readonly numeroLicence: string
}

interface IStep2 {
  documents: Array<Document>;
}

interface IStep3 {
  confirmed: boolean;
}

interface CreateFhNetwork extends IStep1, IStep2, IStep3{}

export default defineComponent({
  name: "declaration-fh",
  components: {
    Step1,
    Step2,
    Step3,
  },
  
  setup() {


    const { setValue:setValueNumeroLicence } = useField('numeroLicence')
    const _stepperObj = ref<StepperComponent | null>(null);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const declarationId = ref<string|null>(null);
    const reseauId = ref<string|null>(null);
    const numeroLicence = ref<string|null>(null);
    var refNumLicence = ref<null | HTMLInputElement>(null);
    var loading = ref<boolean>(true);

    const store = useStore();
    const route = useRoute();
    const topForm = ref();
    const router = useRouter();
    const {bandeIsAutre} = useBande();

    const formData = ref<CreateFhNetwork>({
      documents : [],
      confirmed : false,
      fh : new StepFH(),
      numeroLicence: "",
      declarationId: ""
    });

    onBeforeMount(() => {
      declarationId.value = route.params.declarationId as string;
      reseauId.value = route.params.reseauId as string;

      store.dispatch(Actions.GET_DECLARATION, declarationId.value).then((res) => {
        numeroLicence.value = res.numeroLicence;

        resetForm({
          values: {
            documents : [],
            confirmed : false,
            fh : new StepFH(),
            numeroLicence: numeroLicence.value as string,
            declarationId: declarationId.value as string
          }
        });
      });
    })

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );

      setCurrentPageBreadcrumbs("Nouveau Réseau FH", ["Réseau", "FH"]);

      //Initialisation du formualaire pour la modification du réseau
      if(reseauId.value){
        loading.value = true;
        setCurrentPageBreadcrumbs("Modification Réseau FH", ["Réseau", "FH"]);

        store.dispatch(Actions.GET_RESEAU_BY_ID, reseauId.value)
          .then((data) => {
            loading.value = false;

            formData.value = {
              confirmed: false,
              numeroLicence: numeroLicence.value as string,
              documents: data.documents,
              declarationId: declarationId.value as string,
              fh: data
            }

            resetForm({
              values: {
                ...formData.value
              }
            });

          }, error => {
            //redirection si le réseau là n'existe pas
            router.push({name: "404"});
          });
        
      }else { //Si le param reseauId afficher le formulare quand-même
        loading.value = false;
      }

      refNumLicence.value = document.querySelector("input[name='numeroLicence']");
      refNumLicence.value?.setAttribute('readonly', 'readonly');
    });

    let CreateFhNetworkSchema = [

      // Schema BWWR
      StepFH.validationSchema(),

      Yup.object({documents : Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          uri :  Yup.string()
            .when("id", {
              is: (id) => !id,
              then: Yup.string().matches(RegexList.BASE64_FORMAT,'Le document est invalide.').required()
            }).label("Le document"),
          size : Yup.number().nullable()
            .when("id", {
              is: (id) => !id,
              then: Yup.number().lessThan(2,'Le document ne doit pas exceder 2 MB')
            }).label("La taille du document"),
          type : Yup.string().nullable()
            .when("id", {
              is: (id) => !id,
              then: Yup.string().required().oneOf(Object.values(ACCEPTED_FILETYPE), ACCEPTED_FILETYPE_MESSAGE.TYPE)
            }).label('Le document'),
        })
      ).min(0).max(4)}),

      Yup.object({
        confirmed: Yup.bool().required().equals([true],"La confirmation des données est obligatoire").label("La confirmation des données"),
      }),
    ];

    const currentSchema = computed(() => {
      return CreateFhNetworkSchema[currentStepIndex.value]
    });

    const { resetForm, handleSubmit, errors, setErrors, values, setFieldValue } = useForm<IStep1 | IStep2 | IStep3>({
      validationSchema: currentSchema,
    });

    // Erreurs accessible pour tous les composants enfants
    provide('errors', errors.value)

    // Les étapes totales du wizard
    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    // Vérifie si le wizard est à la fin
    const wizardAtTheEnd = computed(() => {
      var total = (totalSteps.value) ? totalSteps.value : 0
      return currentStepIndex.value == (total - 1);
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
        // on s'assure que la clés des documents est toujours présente
        // dans le formulaire
        var documents = !('documents' in values) ? {...{documents:[]}, ...{}} : {}
        formData.value = {...formData.value,...documents,...values};
        if(wizardAtTheEnd.value == false)
        goNext()
        else
        formSubmit()
    });

    const goNext = () => {
      currentStepIndex.value++;
      if (!_stepperObj.value) 
      {
        return;
      }
      _stepperObj.value.goNext();

      scrollToTop();
    }

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();

      scrollToTop();
    };

    const scrollToTop = () => {
      var element = topForm.value;
      var top = element.offsetTop;

      window.scrollTo(0, top);
    }
    
    const formSubmit = () => {
        let path = "/api/declaration/addFh";
        if(reseauId.value)
          path = `/api/reseau/fh/${reseauId.value}`;
        let data: any ;
        data = buildPathAndData();

        activateLoadingButton(true);

        ApiService[reseauId.value ? 'put' : 'post'](path, data)
          .then( ({ data }) => 
            {
                activateLoadingButton(false);
                var title = "Déclaration enregistrée"
                var message = "<stong>Félicitation</strong>! Vous venez d'enregistrer votre déclaration pour le réseau <strong>FH</strong>!"
                AlertMessage.onSuccess(message, title).then(() => {
                  store.dispatch(ActionsF.GET_RESEAU);
                  router.push({path:`/declarations/details/${declarationId.value}`});
                })
            })
          .catch((error) => {
            AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
            activateLoadingButton(false);
            return false;
          })
    };

    /**
    * Construction du point de terminaison et restructuration des
    * données à soumettre
    * @returns {string, any}
    */
     const buildPathAndData = () => {

      let data: any= JSON.parse(JSON.stringify(formData.value));
      
      // on reforme la structures des données des documents
      data.fh.documents = data.documents.map(obj => {
        var rObj = {};
        if(obj.id)
          rObj["id"] = obj.id;
        else{
          rObj["encodeFile"] = obj.uri;

          //Splits into an array
          var result = obj.name.split('.');  
          //Removes last value and grap the last value       
          result.pop();               
          var finalName = result.join('.');  

          rObj["fileName"] = finalName;
        }        
        return rObj;
      });

      delete data.documents;
      delete data.confirmed;

      if(bandeIsAutre(data.fh.bande.id)){
        data.fh.bande = null;
      }
      
      return data;
    }

     /**
     * Actions sur le bouton de chargement
     * @param {boolean}
     * @returns void
     */
    const activateLoadingButton = (active : boolean = false) => {
      if(submitButtonRef.value) {
        //Disable button
        submitButtonRef.value.disabled = active;
        // Activate indicator
        submitButtonRef?.value.setAttribute("data-kt-indicator", active ? "on" : "off");
      }
    }
    
    watch(verticalWizardRef, (n, o) => {
      //Réinitialisation du composant Stepper
      _stepperObj.value = StepperComponent.createInsance(
        n as HTMLElement
      );
    });

    return {
      verticalWizardRef,
      formData,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      currentSchema,
      CreateFhNetworkSchema,
      submitButtonRef,
      errors,
      topForm,
      wizardAtTheEnd,
      values,
      declarationId,
      reseauId,
      loading
    };
  },
});